/**
 *
 * Cuisine Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    cuisineData, 
} from "../../data/PageAssets";

export default class CuisinePage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                        // lazy loading 
        this._assetPath = "cuisine";                // asset folder for page
        this._assets = cuisineData();               // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd() 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded:lz, hasResized: rz } = this.state;

        const getVid = this.pVideoNam("cuisine_loop");

        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page cuisine"}>
                    <div className={"page-content"}>
                        <h1>Cuisine | Oaxaca is one of Mexico's major gastro-historical centers whose cuisine is known internationally.</h1>
                        <p>With available Chef service, Casa Lupita offers a wide variety of Oaxacan gourmet and traditional foods, 
                        from seafood, to local delicacies such as tlayuda, a handmade dish in traditional Oaxacan cuisine, 
                        consisting of a large, thin, crunchy, partially fried or toasted tortilla  covered with a spread of 
                        refried beans asiento (unrefined pork lard) lettuce or cabbage, avocado, meat...</p>
                        <p>...(usually shredded chicken, beef tenderloin or pork), Oaxaca cheese and salsa.</p>
                        <p>Like the rest of Mexican cuisine, Oaxacan food is based on staples such as corn, beans 
                        and chile peppers, with a great variety of other ingredients and food preparations as 
                        a result of the influence of the state's varied geography and indigenous cultures.</p>
                        <p>From Oaxacan hot chocolate with spices and other flavorings to Oaxacan cheese and mezcal our chef will 
                        prepare you traditional local tamales, wrapped in banana leaves with a mole filling as well as other 
                        Oaxacan dishes like chorizo oaxaqueño,  cecina enchilada, tasajo and various sauces such as molcajete, 
                        chintextle, borracha, chile pasilla, guajillo y ajo and gusanitos.</p>
                        <p>Our chef will be happy to take you and your entire family on a culinary journey none of you will forget.</p>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("cuisine_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Cuisine"
                                    body={"Oaxaca is one of Mexico's major gastro-historical centers whose cuisine is known internationally."}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    classes="assorted-tacos"
                                    alt="Assorted tacos on wooden plate"
                                    asset={this.assetRef("assorted_tacos_on_wooden_plate")}
                                    x={980} y={89} width={815} height={849}
                                    author={ { name: "Edgar Castrejon", theme: "dark", position: "bottom-right", class: "bottom-out" } }
                                />
                                
                                <TextLayer classes="bottom-space" titleText="Me Gusta!" x={340} y={100} width={464} height={525}>
                                    <p>With available Chef service, Casa Lupita offers a wide variety of Oaxacan gourmet and traditional foods, 
                                        from seafood, to local delicacies such as tlayuda, a handmade dish in traditional Oaxacan cuisine, 
                                        consisting of a large, thin, crunchy, partially fried or toasted tortilla  covered with a spread of 
                                        refried beans asiento (unrefined pork lard) lettuce or cabbage, avocado, meat...</p>
                                </TextLayer>
                                
                                <ImageLayer 
                                    alt="Cooked tacos"
                                    asset={this.assetRef("cooked_tacos")}
                                    x={0} y={770} width={805} height={974}
                                    author={ { name: "Chad Montano", theme: "dark", position: "bottom-right", class: "bottom-out" } }
                                />
                                
                                <ImageLayer 
                                    classes="silver-spoon"
                                    alt="silver spoon on black ceramic bowl with vegetables"
                                    asset={this.assetRef("silver_spoon_on_black_bowl")}
                                    x={1476} y={1019} width={476} height={474}
                                    author={ { name: "Chad Montano", theme: "dark", position: "bottom-left", class: "bottom-out" } }
                                />
                                
                                <TextLayer classes="bottom-space" x={914} y={1110} width={480} height={220}>
                                    <Fragment>
                                        <p>...(usually shredded chicken, beef tenderloin or pork), Oaxaca cheese and salsa.</p>
                                        <p>Like the rest of Mexican cuisine, Oaxacan food is based on staples such as corn, beans 
                                            and chile peppers, with a great variety of other ingredients and food preparations as 
                                            a result of the influence of the state's varied geography and indigenous cultures.</p>
                                    </Fragment>
                                </TextLayer>

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    alt="Luxury dining"
                                    asset={this.assetRef("luxury_dinner_table")}
                                    x={0} y={0} width={1920} height={1291}
                                />

                            </ModuleLayer>

                            <ModuleLayer backgroundColor="white" {...mProps}>

                                <ImageLayer 
                                    alt="Clear glass goblet"
                                    asset={this.assetRef("clear_glass_goblet")}
                                    x={926} y={107} width={880} height={880}
                                    author={ { name: "Jez Timms", theme: "dark", position: "bottom-right", class: "bottom-out" } }
                                />
                                
                                <TextLayer x={204} y={127} width={600} height={320}>
                                    From Oaxacan hot chocolate with spices and other flavorings to Oaxacan cheese and mezcal our chef will 
                                    prepare you traditional local tamales, wrapped in banana leaves with a mole filling as well as other 
                                    Oaxacan dishes like chorizo oaxaqueño,  cecina enchilada, tasajo and various sauces such as molcajete, 
                                    chintextle, borracha, chile pasilla, guajillo y ajo and gusanitos.
                                    <p>Our chef will be happy to take you and your entire family on a culinary journey none of you will forget.</p>
                                </TextLayer>

                                <ImageLayer 
                                    classes="assorted-sliced bottom-space"
                                    alt="Assorted sliced citrus fruit"
                                    asset={this.assetRef("assorted_sliced_citrus_fruit")}
                                    x={204} y={643} width={503} height={350}
                                    author={ { name: "Edgar Castrejon", theme: "dark", position: "bottom-right", class: "bottom-out" } }
                                />

                            </ModuleLayer>
                            
                            <ModuleLayer 
                                backgroundColor="white" 
                                paddingBottom={107} 
                                {...mProps}
                            >
                                <ImageLayer 
                                    alt="Exterior balcony with table"
                                    asset={this.assetRef("exterior_balcony_table")}
                                    x={0} y={0} width={1301} height={882}
                                />
                                
                                <ImageLayer 
                                    alt="Assorted pies and tarts on plate"
                                    asset={this.assetRef("assorted_pies_and_tarts_plate")}
                                    x={1327} y={0} width={590} height={882}
                                    author={ { name: "Victoria Shes", theme: "light", position: "bottom-left", class: "desktop-out" } }
                                />

                            </ModuleLayer>

                            <ModuleLayer
                                backgroundColor="white" 
                                {...mProps}
                                animate="push"
                            >
                                <ImageLayer 
                                    alt="Assorted vegatables"
                                    asset={this.assetRef("assorted_vegatables")}
                                    x={0} y={0} width={1920} height={1291}
                                    author={ { name: "Megan Thomas", theme: "light", position: "bottom-left" } }
                                />
                            </ModuleLayer>

                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>
                            
                        </div>
                    }

                </article>
            </Fragment>
        )
    }   
}